import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Image1 from "../../../assets/carousel/1.png";
import Image2 from "../../../assets/carousel/2.png";
import Image3 from "../../../assets/carousel/3.png";
import Image4 from "../../../assets/carousel/4.png";
import Image5 from "../../../assets/carousel/5.png";
import Image1g from "../../../assets/carousel/1g.png";
import Image3g from "../../../assets/carousel/3g.png";
import Image4g from "../../../assets/carousel/4g.png";
import Image5g from "../../../assets/carousel/5g.png";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../features/langSlice";

const CarouselProduct = () => {
  const selectedLang = useSelector(selectCurrentLanguage);
  console.log("selectedLang", selectedLang);
  return (
    <Carousel
      showArrows={false}
      showIndicators={true}
      showThumbs={false}
      showStatus={false}
      preventMovementUntilSwipeScrollTolerance={true}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        return (
          <li
            className={`dot ${isSelected ? "selected" : ""}`}
            style={{
              display: "inline-block",
              margin: "0 5px",
              cursor: "pointer",
              width: 10,
              height: 10,
              background: isSelected ? "#A6A6A6" : "#aaa",
              //   background: "red",
              borderRadius: "50%",
              boxShadow: "0 0 0 0",
            }}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            role="button"
            tabIndex={0}
            aria-label={`Slide ${index + 1} ${label}`}
          />
        );
      }}
    >
      <div>
        <img src={selectedLang === "us" ? Image1 : Image1g} alt="" />
      </div>
      <div>
        <img src={Image2} alt="" />
      </div>
      <div>
        <img src={selectedLang === "us" ? Image3 : Image3g} alt="" />
      </div>
      <div>
        <img src={selectedLang === "us" ? Image4 : Image4g} alt="" />
      </div>
      <div>
        <img src={selectedLang === "us" ? Image5 : Image5g} alt="" />
      </div>
    </Carousel>
  );
};

export default CarouselProduct;
