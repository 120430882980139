import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import ChristmasTree from "../assets/christmax-tree.png";
import WrappedGift from "../assets/wrapped-gift.png";

const CountdownTimer = ({ isHomepage }) => {
  // Target date in CET
  const quiz_id = localStorage.getItem("quiz_id");
  const [targetDate] = useState(
    moment.tz("2024-12-17 23:59:00", "Europe/Paris")
  );
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const updateTimer = () => {
      const now = moment().tz("Europe/Paris"); // Current CET time
      const diff = moment.duration(targetDate.diff(now));

      if (diff.asSeconds() <= 0) {
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        return;
      }

      // Calculate remaining days, hours, minutes, and seconds
      const days = Math.floor(diff.asDays());
      const hours = Math.floor(diff.asHours() % 24); // Remaining hours after days
      const minutes = Math.floor(diff.asMinutes() % 60); // Remaining minutes after hours
      const seconds = Math.floor(diff.asSeconds() % 60); // Remaining seconds after minutes

      setTimeLeft({ days, hours, minutes, seconds });
    };

    const intervalId = setInterval(updateTimer, 1000);
    updateTimer(); // Run immediately

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [targetDate]);

  const { days, hours, minutes, seconds } = timeLeft;

  return isHomepage ? (
    <Link
      to={quiz_id ? "/product-suggestion" : "/quiz"}
      className="t-w-fit t-flex t-items-center t-bg-black !t-text-xs t-text-white hover:t-text-white t-px-4 t-py-1 t-rounded-lg"
    >
      <span className="t-mr-2">🕒</span>
      <img src={ChristmasTree} alt="" height={12} width={20} />
      <span className="t-font-semibold">Xmas offer</span>
      <img src={WrappedGift} alt="" height={12} width={20} />
      <span className="t-ml-2 t-font-bold t-text-primary">
        {days > 0 && `${days}d `}
        {String(hours).padStart(2, "0")}h {String(minutes).padStart(2, "0")}m{" "}
        {String(seconds).padStart(2, "0")}s
      </span>
    </Link>
  ) : (
    // <div className="t-flex t-items-center t-justify-center t-bg-white t-p-2">
    <div className="t-w-fit t-flex t-items-center t-bg-black !t-text-xs t-text-white hover:t-text-white t-px-4 t-py-1 t-rounded-lg">
      <span className="t-mr-2">🕒</span>
      <img src={ChristmasTree} alt="" height={12} width={20} />
      <span className="t-font-semibold">Xmas offer</span>
      <img src={WrappedGift} alt="" height={12} width={20} />
      <span className="t-ml-2 t-font-bold t-text-primary">
        {days > 0 && `${days}d `}
        {String(hours).padStart(2, "0")}h {String(minutes).padStart(2, "0")}m{" "}
        {String(seconds).padStart(2, "0")}s
      </span>
    </div>
    // </div>
  );
};

export default CountdownTimer;
