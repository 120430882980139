import React, { useState, useEffect } from "react";

import { useGetBlogsQuery } from "../../features/blogApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../features/langSlice";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { OurIngredients } from "../../Constants/index";

const Ingredients = () => {
  const selectedLang = useSelector(selectCurrentLanguage);
  const [lang, setLang] = useState();
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  const {
    data: fetchBlogs,
    isSuccess,
    refetch,
  } = useGetBlogsQuery({
    lang: lang,
    page: 1,
    rows: 100,
    category: "ingredient",
  });

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);

    refetch();
  }, [refetch, lang, selectedLang]);

  useEffect(() => {
    if (isSuccess) {
      setData(fetchBlogs.data);
    }
  }, [fetchBlogs, isSuccess]);

  return (
    <>
      <Helmet>
        <title>Riise Ingredients</title>
        <meta name="description" content="Riise ingredient" />
      </Helmet>
      <section className="t-flex t-flex-col">
        <div className="t-py-6 t-bg-gray-100">
          <div className="container">
            <nav aria-label="Page breadcrumb">
              <h2 className="title2">{t(OurIngredients)}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  <Link to={"/"} className="breadcrumb-item !t-text-gray-500">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item !t-text-gray-500">
                  {t(OurIngredients)}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="services blogs section-padding t-min-h-[100vh]">
        <div className="container">
          <div className="t-grid t-grid-cols-2 md:t-grid-cols-3 lg:t-grid-cols-4 t-gap-4">
            {data.map((ingred, index) => (
              <div
                className="t-bg-white t-overflow-hidden t-align-top t-flex t-flex-col"
                key={index}
              >
                <div className="t-w-full t-aspect-square t-relative t-rounded-lg t-overflow-hidden">
                  <Link to={`/ingredients/${ingred.slug}`}>
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}${ingred.picture}`}
                      alt={ingred.title}
                      className="t-absolute t-inset-0 t-h-full t-object-cover t-rounded-lg" // t-w-full
                    />
                  </Link>
                </div>
                <div className="t-text-sm t-text-black t-mt-2">
                  <Link to={`/ingredients/${ingred.slug}`}>
                    <h4 className="t-text-lg t-font-semibold t-text-secondary">
                      {ingred.title}
                    </h4>
                  </Link>
                  <a
                    href={`/ingredients/${ingred.slug}`}
                    className="full-paragraph-link t-text-black"
                  >
                    {ingred.contents}{" "}
                    <span className="read-more-text">... Read more</span>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Ingredients;
