import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Banner from "./Banner";
import ReviewsOverlay from "../Reviews";
import { useGetAssetsQuery } from "../../features/quizApiSlice";
import PersonalFormulasSection from "../Home/PersonalFormulasSection";
import { useTranslation } from "react-i18next";
import {
  CustomerReviews,
  FormulaHeading,
  FormulatedByExperts,
  FreeShippingBanner,
} from "../../Constants";
import ExpertSection from "../Customer/components/ExpertSection";
import { Link } from "react-router-dom";
import Reviews from "../Customer/components/Reviews";

const RiisePartner = () => {
  const { t } = useTranslation();
  const [reviewData, setReviewData] = useState();
  const { data: news } = useGetAssetsQuery("homepageNews");

  const bannerRef = useRef(null);
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBannerVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const reviewsCount = document.querySelectorAll(
        ".cssVar-subheading__number"
      );
      if (reviewsCount) {
        const reviews = [];
        reviewsCount.forEach((element) => {
          console.log("review => ", element.innerHTML);
          reviews.push(element.innerHTML);
        });
        setReviewData(reviews);
      }
    });

    // Start observing the target node for DOM changes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
  }, []);
  return (
    <div>
      <Header showButton={!isBannerVisible} />
      <p
        className="t-text-center t-text-lg t-py-1 t-bg-primary t-text-black t-font-bold"
        style={{ marginBottom: "0px" }}
      >
        {t(FreeShippingBanner)}
      </p>
      <div className="!t-max-w-[1330px] !t-mx-auto t-flex t-flex-col t-py-4">
        <div ref={bannerRef}>
          <Banner />
        </div>
        <section className="t-flex t-items-center t-justify-center t-py-2 t-bg-gray-100">
          <ReviewsOverlay
            totalReviews={reviewData?.[1]?.trim() ?? 47}
            averageReviews={reviewData?.[0]?.trim() ?? 4.93}
          />
        </section>
        <section className="section-padding !t-pt-4 !t-pb-0">
          <h1 className="t-text-[18px] t-text-center t-font-bold t-mb-0 t-text-tertiary">
            As seen in
          </h1>
          <div className="container t-flex t-flex-row t-items-center t-justify-center t-gap-2 md:t-gap-4 lg:t-gap-8">
            {news?.data?.map((item) => (
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}${item?.url}`}
                alt=""
                className="t-w-16 t-h-auto md:t-w-[5%]"
                key={"news-img-" + item.url}
              />
            ))}
          </div>
        </section>
        <div className="t-flex t-flex-col t-gap-4 t-bg-gray-100 t-justify-center t-items-center t-p-4">
          <h1 className="t-text-[18px] t-text-center t-font-bold t-mb-0 t-text-tertiary">
            {t(FormulaHeading)}
          </h1>
          <PersonalFormulasSection />
          <Link to="/custom-quiz?partner_id=carrotcare">
            <button className="btn !t-w-fit !t-bg-primary t-text-black !t-rounded-md">
              Personalize mine
            </button>
          </Link>
        </div>
        <div className="t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4 t-p-4">
          <h1 className="t-text-[18px] t-text-center t-font-bold t-mb-0 t-text-tertiary">
            {t(FormulatedByExperts)}
          </h1>
          <ExpertSection />
        </div>
        <div className="t-flex t-flex-col t-gap-4 t-overflow-auto t-mt-4 t-p-4 t-bg-gray-100">
          <h1 className="t-text-[18px] t-text-center t-font-bold t-mb-0 t-text-tertiary">
            {t(CustomerReviews)}
          </h1>
          <Reviews />
        </div>
        <Link
          to="/custom-quiz?partner_id=carrotcare"
          className="t-p-4 t-flex t-items-center t-justify-center"
        >
          <button className="btn !t-w-fit text-capitalize !t-bg-primary t-text-black !t-rounded-md">
            Start now
          </button>
        </Link>
      </div>
    </div>
  );
};

export default RiisePartner;
