import React, { useMemo, useState } from "react";
import FocusAreaCard from "./FocusAreaCard";
import { Icon } from "../../elements/icon";

const FocusAreaAccordion = ({ focusArea, focusAreaImages, focusAreaIcons }) => {
  const [open, setOpen] = useState(false);

  const updatedFocusArea = useMemo(
    () => (open ? focusArea : focusArea.slice(0, 2)),
    [open, focusArea]
  );

  return (
    <div className="t-flex t-flex-col t-gap1">
      <div className="t-flex t-flex-wrap t-gap-2">
        {updatedFocusArea &&
          updatedFocusArea.map((item) => {
            const focusImage = focusAreaImages?.data?.find(
              (i) => i?.name === item?.answer
            );
            const focusIcon = focusAreaIcons?.data?.find(
              (i) => i?.name === item?.answer
            );
            return (
              <FocusAreaCard
                icon={focusIcon?.url}
                bg={focusImage?.url}
                name={item.answer}
                key={"focus-area-" + item.answer}
              />
            );
          })}
      </div>
      {focusArea.length > 2 && (
        <div
          className="t-w-full  t-flex t-items-center t-justify-center t-gap-1 t-cursor-pointer"
          onClick={() => setOpen(!open)}
        >
          <Icon
            name={open ? "chevronUp" : "chevronDown"}
            color="gray"
            size="16"
            className="t-mt-1"
          />
          <p className="t-m-0 t-text-gray-500 t-text-center">
            {open ? "" : "+"} {!open && focusArea.length - 2}{" "}
            {open ? "hide" : "others"}
          </p>
        </div>
      )}
    </div>
  );
};

export default FocusAreaAccordion;
