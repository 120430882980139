import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../features/langSlice";
import { useGetContentsQuery } from "../features/contentsApiSlice";

import { Buffer } from "buffer";
import { Link } from "react-router-dom";
import Loader from "./elements/Loader";

const TermsConditions = () => {
  const id = Buffer.from("3").toString("base64");
  const selectedLang = useSelector(selectCurrentLanguage);
  const [lang, setLang] = useState();
  const [data, setData] = useState([]);

  const [skip] = useState(false);
  const {
    data: fetchContents,
    isSuccess,
    isLoading,
    refetch,
  } = useGetContentsQuery({ id: id, lang: lang, skip: skip });

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);

    refetch();
  }, [refetch, lang, selectedLang]);

  useEffect(() => {
    if (isSuccess) {
      setData(fetchContents.data);
    }
  }, [fetchContents, isSuccess]);

  return (
    <>
      <section className="t-min-h-[100px]">
        <div className="t-pt-6">
          <div className="container">
            <nav aria-label="Page breadcrumb">
              <h2 className="title2 !t-text-[24px]">{data.title}</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  <Link to={"/"} className="breadcrumb-item !t-text-gray-500">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item !t-text-gray-500">
                  {data.title}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <section className="blog-details section-padding t-min-h-[100vh]">
        <div className="container">
          <div className="row gx-sm-5 gy-4">
            <div className="col-xl-12">
              <div className="blog-detail-inner">
                <span dangerouslySetInnerHTML={{ __html: data.contents }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {isLoading && <Loader />}
    </>
  );
};

export default TermsConditions;
