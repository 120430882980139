import React from "react";
import PersonalFormulaCard from "./PersonalFormulaCard";
import { useGetAssetsQuery } from "../../features/quizApiSlice";
import Bg1 from "../../assets/formulas/Bg1.png";
import User1 from "../../assets/formulas/User1.png";
import Bg2 from "../../assets/formulas/Bg2.png";
import User2 from "../../assets/formulas/User2.png";
import Bg3 from "../../assets/formulas/Bg3.png";
import User3 from "../../assets/formulas/User3.png";
import { useTranslation } from "react-i18next";
import {
  Adina,
  Chris,
  Month,
  MonthlyPlan,
  Neil,
  ThreeMonthPlan,
  TryOnce,
} from "../../Constants";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../features/langSlice";

const PersonalFormulasSection = ({ isProductPage = false }) => {
  const { data: focusAreaImages } = useGetAssetsQuery("focusArea");
  const { data: focusAreaIcons } = useGetAssetsQuery("focusAreaIcon");
  const { t } = useTranslation();
  const selectedLang = useSelector(selectCurrentLanguage);

  const DATA = [
    {
      userImg: User1,
      bg: Bg1,
      name: "Niels, 25",
      focusAreas: selectedLang === "de" ? [128, 119] : [13, 3], //["Muscle Pain", "Sport Performance"], ids in german and english
      desc: t(Neil),
      price: `42€/${t(Month)}`,
      duration: t(ThreeMonthPlan),
      nutrients: 22,
    },
    {
      userImg: User2,
      bg: Bg2,
      name: "Adina, 28",
      focusAreas: selectedLang === "de" ? [115, 122] : [2, 7], // ["Energy", "Sleep"],
      desc: t(Adina),
      price: `53€/${t(Month)}`,
      duration: t(MonthlyPlan),
      nutrients: 17,
    },
    {
      userImg: User3,
      bg: Bg3,
      name: "Chris, 41",
      focusAreas: selectedLang === "de" ? [5, 121] : [5, 6], // ["Stress", "Immune System"],
      desc: t(Chris),
      price: `61€/${t(Month)}`,
      duration: t(TryOnce),
      nutrients: 25,
    },
  ];

  return (
    <div
      className={`t-flex t-items-start t-gap-8 t-w-full t-overflow-scroll t-mx-auto hide-scrollbar ${
        isProductPage ? "" : " lg:t-items-center lg:t-justify-center t-px-8"
      }`}
    >
      {DATA.map((item) => (
        <PersonalFormulaCard
          key={item.name}
          userImg={item.userImg}
          bg={item.bg}
          name={item.name}
          focusArea={item.focusAreas}
          desc={item.desc}
          price={item.price}
          duration={item.duration}
          nutrients={item.nutrients}
          focusAreaImages={focusAreaImages?.data ?? []}
          focusAreaIcons={focusAreaIcons?.data ?? []}
          isProductPage={isProductPage}
        />
      ))}
    </div>
  );
};

export default PersonalFormulasSection;
