import React from "react";
import { Icon } from "./elements/icon";
import { useTranslation } from "react-i18next";
import OneMonth from "../assets/product/1m-desktop.jpg";
import ThreeMonth from "../assets/product/3m-desktop.jpg";
import SixMonth from "../assets/product/6m-desktop.jpg";

import {
  Box1mLine1,
  Box1mLine2,
  Box1mLine3,
  Box1mLine4,
  Box3mLine1,
  Box6mLine1,
  Box6mLine2,
  Box6mLine3,
  Box6mLine4,
  TryOnce,
  MonthlyPlan,
  ThreeMonthPlan,
  PerDay,
  Month,
  Selected,
  Select,
  MostPopular,
  BestValue,
  TryOnceBox1,
  TryOnceBox2,
} from "../Constants";

const SubscriptionCard = ({
  item,
  onSelectPlan,
  subscribtion,
  id,
  totals,
  loading,
  showSelectBtn = true,
}) => {
  console.log("item", item);
  const { t } = useTranslation();
  return (
    <div className="t-relative !t-w-full t-bg-white !t-max-w-[300px] sm:!t-w-[200px] t-flex t-flex-col t-px-2 md:t-px-4 t-py-4 md:t-py-6 t-border t-border-gry-200 t-rounded-lg t-gap-2">
      <img
        src={
          item.duration === "6 months"
            ? SixMonth
            : item.duration === "30 days"
            ? OneMonth
            : ThreeMonth
        }
        alt=""
        className={`t-hidden sm:t-block ${
          item.duration === "30 days" ? "t-w-[60%]" : "t-w-[60%]"
        }  t-h-auto t-mx-auto`}
      />
      <p className="t-text-xl sm:t-text-lg !t-font-[700] !t-leading-normal t-mb-0 !t-mt-1 t-max-w-[80%] sm:t-max-w-[100%] sm:t-text-center">
        <span className="t-text-gray-400 t-line-through">
          {["6 months", "3 months"].includes(item.duration) &&
            Math.round(parseFloat(totals["oneMonth"])) + "€"}
        </span>
        {item.duration === "6 months"
          ? Math.round(parseFloat(totals["sixMonths"]))
          : item.duration === "3 months"
          ? Math.round(parseFloat(totals["threeMonths"]))
          : Math.round(parseFloat(totals["oneMonth"]))}
        €{item.duration === "30 days" ? "" : "/" + t(Month)}
      </p>
      <p className="t-text-gray-400 t-text-sm t-mb-0 sm:t-text-center">
        {item.duration === "6 months"
          ? (Math.round(parseFloat(totals["sixMonths"])) / 30).toFixed(2) +
            `€ ${t(PerDay)}`
          : item.duration === "3 months"
          ? (Math.round(parseFloat(totals["threeMonths"])) / 30).toFixed(2) +
            `€ ${t(PerDay)}`
          : (Math.round(parseFloat(totals["oneMonth"])) / 30).toFixed(2) +
            `€ ${t(PerDay)}`}
      </p>
      <p className="t-text-xl sm:t-text-lg !t-font-[600] !t-leading-normal t-mb-0 t-max-w-[80%] sm:t-max-w-[100%] sm:t-text-center">
        {item.duration === "30 days"
          ? t(TryOnce)
          : item.duration === "3 months"
          ? t(MonthlyPlan)
          : item.duration === "1 month"
          ? t(MonthlyPlan)
          : t(ThreeMonthPlan)}
      </p>
      {/* temporarily switch off buy boxes */}
      {showSelectBtn && (
        <button
          className="!t-text-sm !t-text-capitalize btn-custom-quaternary !t-py-2 !t-bg-secondary disabled:!t-bg-quaternary hover:!t-text-white !t-rounded-lg"
          onClick={() => onSelectPlan(item, id)}
          disabled={loading || subscribtion?.subId === item?.id}
        >
          {subscribtion?.subId === item?.id ? t(Selected) : t(Select)}
        </button>
      )}
      {/* end */}
      <div className="t-flex t-flex-col !t-gap-1">
        <div className="t-flex t-items-start t-gap-2">
          <Icon name="tick" size="16" className="t-mt-1" />
          <p className="t-text-sm t-mb-0">
            {item.duration === "6 months"
              ? t(Box6mLine1)
              : item.duration === "3 months"
              ? t(Box3mLine1)
              : item.duration === "30 days"
              ? t(TryOnceBox1)
              : t(Box1mLine1)}
          </p>
        </div>
        <div className="t-flex t-items-start t-gap-2">
          <Icon name="tick" size="16" className="t-mt-1" />
          <p className="t-text-sm t-mb-0">
            {item.duration === "6 months"
              ? t(Box6mLine2)
              : item.duration === "30 days"
              ? t(TryOnceBox2)
              : t(Box1mLine2)}
          </p>
        </div>
        {item.duration !== "30 days" && (
          <div className="t-flex t-items-start t-gap-2">
            <Icon name="tick" size="16" className="t-mt-1" />
            <p className="t-text-sm t-mb-0">
              {item.duration === "6 months" ? t(Box6mLine3) : t(Box1mLine3)}
            </p>
          </div>
        )}
        {item.duration !== "30 days" && (
          <div className="t-flex t-items-start t-gap-2">
            <Icon name="tick" size="16" className="t-mt-1" />
            <p className="t-text-sm t-mb-0">
              {item.duration === "6 months" ? t(Box6mLine4) : t(Box1mLine4)}
            </p>
          </div>
        )}
      </div>
      {/* previous position of the button */}
      <p className="t-absolute t-top-2 t-right-2 t-rounded-full t-bg-secondary t-text-white t-px-2 t-text-[10px]">
        {item.duration === "3 months"
          ? t(MostPopular)
          : item.duration === "6 months"
          ? t(BestValue)
          : null}
      </p>
      <img
        src={`${process.env.REACT_APP_PUBLIC_URL}${item.picture}`}
        alt=""
        className="t-absolute t-top-6 t-right-2 sm:t-hidden t-w-[60px] t-h-auto t-mx-auto"
      />
    </div>
  );
};

export default SubscriptionCard;
