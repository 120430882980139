import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../features/langSlice";
import { useGetContentsByTagWebQuery } from "../../features/contentsApiSlice";
import EffectAccordion from "./EffectAccordion";
import { selectSlug } from "../../features/contentsSlice";
import { Link } from "react-router-dom";
import { useGetAssetsQuery } from "../../features/quizApiSlice";
import FranceFlag from "../../assets/images/france.png";
import GermanyFlag from "../../assets/images/germany.webp";
import NoMorePillsVideo from "../../assets/riise-people-sport-energy-granules.mp4";
import productImg from "../../assets/yellow-paper-pouch-granules.png";
import Whatsapp from "../../assets/here1.png";

import {
  MixedIn,
  ProducedIn,
  AsSeenIn,
  WhatAreYourWellnessGoals,
  ItMattersToUs,
  CustomerReviews,
  FormulaHeading,
  HereMainHeading,
  PersonaliseMine,
  FreeShippingBanner,
  BlackFriday,
  Off25,
} from "../../Constants";
import Reviews from "../Customer/components/Reviews";
import { FOCUS_AREA_LINKS } from "../../utils";
import ReviewsOverlay from "../Reviews";
import PersonalFormulasSection from "./PersonalFormulasSection";
import HereForYou from "./HereForYou";
import CountdownTimer from "../CountdownTimer";

const Home = () => {
  const selectedLang = useSelector(selectCurrentLanguage);
  const { t } = useTranslation();
  const [lang, setLang] = useState();
  const dispatch = useDispatch();
  const [mainSlides, setMainSlides] = useState(2);
  const [takeTheTestTitle, setTakeTheTestTitle] = useState("");
  const [pictures, setPictures] = useState([]);
  const slug = useSelector(selectSlug);
  const token = localStorage.getItem("customer_token");
  const quiz_id = localStorage.getItem("quiz_id");
  const [picture1, setPicture1] = useState("");
  const [picture4, setPicture4] = useState("");
  const [picture5, setPicture5] = useState("");

  const [picture7, setPicture7] = useState("");
  const [picture8, setPicture8] = useState("");

  const [picture9, setPicture9] = useState("");
  const [picture10, setPicture10] = useState("");
  const [picture11, setPicture11] = useState("");
  const [picture12, setPicture12] = useState("");

  const [picture13, setPicture13] = useState("");
  const [picture14, setPicture14] = useState("");
  const [picture15, setPicture15] = useState("");
  const [picture16, setPicture16] = useState("");

  const [picture17, setPicture17] = useState("");
  const [picture18, setPicture18] = useState("");
  const [picture19, setPicture19] = useState("");
  const [picture20, setPicture20] = useState("");
  const [picture21, setPicture21] = useState("");
  const [picture22, setPicture22] = useState("");

  const [expertsData, setExpertsData] = useState([]);

  const [data, setData] = useState([]);
  const [skip, setSkip] = useState(false);

  const [video, setVideo] = useState(
    `${process.env.REACT_APP_PUBLIC_URL}resources/videos/home-page.mp4`
  );

  const {
    data: fetchContents,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetContentsByTagWebQuery({ tag: "home", lang: lang, skip: skip });

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);

    refetch();
  }, [refetch, lang, selectedLang]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleResize = () => {
    //console.log('width: ' + window.innerWidth);

    if (window.innerWidth > 1024) {
      setMainSlides(3);
    } else if (window.innerWidth > 768) {
      setMainSlides(2);
    } else if (window.innerWidth > 0) {
      setMainSlides(1);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setData(fetchContents.data);
      //console.log(fetchContents.data)
      //setPictures([])

      if (fetchContents.data.length > 0) {
        // this message used in header
        //dispatch(setFreeShippingOrders({content: fetchContents.data[22].title}));
        //dispatch(setCookieBannerMessage({message: fetchContents.data[23].contents}));
        //dispatch(setTakeTheTest({title: fetchContents.data[24].title}))
        //dispatch(setSlug({slug: fetchContents.data[25].slug}))

        setTakeTheTestTitle(fetchContents.data[24].title);

        //console.log(`${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[0].picture}`)

        let pics = [];

        setPicture1(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[0].picture}`
        );
        setPicture4(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[3].picture}`
        );
        setPicture5(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[4].picture}`
        );
        setPicture7(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[6].picture}`
        );
        setPicture8(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[7].picture}`
        );

        setPicture9(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[8].picture}`
        );

        setPicture10(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[9].picture}`
        );
        setPicture11(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[10].picture}`
        );
        setPicture12(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[11].picture}`
        );

        //console.log('pics: ', fetchContents.data[8].picture)

        pics.push(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[4].picture}`
        );
        pics.push(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[6].picture}`
        );
        pics.push(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[7].picture}`
        );

        expertsData.push({ data: fetchContents.data[6], picture: pics[1] });
        expertsData.push({ data: fetchContents.data[7], picture: pics[2] });

        setPicture13(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[12].picture}`
        );
        setPicture14(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[13].picture}`
        );
        setPicture15(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[14].picture}`
        );
        setPicture16(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[15].picture}`
        );

        setPicture17(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[16].picture}`
        );
        setPicture18(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[17].picture}`
        );
        setPicture19(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[18].picture}`
        );
        setPicture20(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[19].picture}`
        );
        setPicture21(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[20].picture}`
        );
        setPicture22(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[21].picture}`
        );

        setPictures(pics);
      }

      //console.log(exportsData)
      //console.log(`${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[4].picture}`)
      //console.log(data[0].contents)
    }
  }, [fetchContents]);

  const [reviewData, setReviewData] = useState();
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const reviewsCount = document.querySelectorAll(
        ".cssVar-subheading__number"
      );
      if (reviewsCount) {
        const reviews = [];
        reviewsCount.forEach((element) => {
          console.log("review => ", element.innerHTML);
          reviews.push(element.innerHTML);
        });
        setReviewData(reviews);
      }
    });

    // Start observing the target node for DOM changes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Clean up the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  const sectionRef = useRef(null);
  const scrollToSectionEnd = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  const { data: news } = useGetAssetsQuery("homepageNews");

  const { data: focusAreas } = useGetAssetsQuery("homepageFocusArea");

  const filteredFocusAreas = useMemo(
    () =>
      lang === "en"
        ? focusAreas?.data?.filter((_item, i) => i <= 14)
        : focusAreas?.data?.filter((_item, i) => i >= 15),
    [focusAreas, lang]
  );

  const { data: focusAreaIcons } = useGetAssetsQuery("focusAreaIcon");

  return (
    <>
      <div className="t-flex t-bg-black t-py-2 t-items-center t-justify-center t-gap-1">
        <Link
          to={quiz_id ? "/product-suggestion" : "/quiz"}
          className="t-flex t-items-center t-font-bold t-text-white t-text-base hover:t-text-white"
        >
          <p className="t-m-0">{t(BlackFriday)}</p>
          <p className="t-m-0 t-italic t-underline">{t(Off25)}&nbsp;&nbsp;</p>
          <p className="t-m-0 t-underline"> {t(FreeShippingBanner)}</p>
        </Link>
      </div>
      <main>
        <section className="home-banner">
          <div className="container">
            <div className="home-text-wrapper t-h-[500px] md:!t-h-[520px]">
              <div className="row align-items-center !-t-mt-8">
                <div className="col-xxl-6 col-lg-8">
                  <div className="home-text">
                    {data[0] && (
                      <span
                        dangerouslySetInnerHTML={{ __html: data[0]?.contents }}
                      />
                    )}
                    {/*<Link to={`/quiz`} className="btn btn-custom-tertiary">{takeTheTestTitle}</Link>*/}
                    <div className="t-flex !t-items-center t-gap-1 ">
                      <p className="t-text-sm md:t-text-[15px]">
                        {t(ProducedIn)}
                      </p>
                      <img
                        src={FranceFlag}
                        alt=""
                        className="t-w-4 t-h-3 -t-mt-3"
                        width="16"
                        height="12"
                      />
                      <p className="t-text-sm md:t-text-[15px]">{t(MixedIn)}</p>
                      <img
                        src={GermanyFlag}
                        alt=""
                        className="t-w-4 t-h-3 -t-mt-3"
                        width="16"
                        height="12"
                      />
                    </div>
                    <Link to={slug}>
                      <span className="btn btn-custom-tertiary !t-rounded-lg">
                        {takeTheTestTitle}
                      </span>
                    </Link>
                  </div>
                  <div
                    className={`t-flex ${
                      selectedLang === "us" ? "t-mt-36" : "t-mt-32"
                    }  md:t-mt-8`}
                  >
                    <CountdownTimer isHomepage />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="t-flex md:t-hidden t-items-center t-justify-center t-p-2">
          <CountdownTimer isHomepage />
        </div> */}
        <section className="t-flex t-items-center t-justify-center t-py-2 t-bg-gray-100 t-h-10">
          <ReviewsOverlay
            totalReviews={reviewData?.[1]?.trim() ?? 0}
            averageReviews={reviewData?.[0]?.trim() ?? 0}
            onClick={scrollToSectionEnd}
          />
        </section>

        <section className="section-padding !t-pt-4 !t-pb-0 t-h-[102px]">
          <h1 className="t-text-[18px] t-text-center t-font-bold t-mb-0 t-text-tertiary">
            {t(AsSeenIn)}
          </h1>
          <div className="container t-flex t-flex-row t-items-center t-justify-center t-gap-2 md:t-gap-4 lg:t-gap-8">
            {news?.data?.map((item) => (
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}${item?.url}`}
                alt=""
                className="t-w-16 t-h-auto md:t-w-[5%]"
                key={"news-img-" + item.url}
              />
            ))}
          </div>
        </section>

        <section className="section-padding !t-px-8 t-h-[330px] md:t-h-[400px]">
          <h1 className="t-text-[24px] md:t-text-[30px] t-text-center t-font-bold t-mb-8 t-text-tertiary">
            {t(WhatAreYourWellnessGoals)}
          </h1>
          <div className="container !t-px-0 t-flex t-flex-row t-gap-2 md:t-gap-4 t-overflow-auto hide-scrollbar ">
            {filteredFocusAreas?.map((item, index) => {
              const focusIcon = focusAreaIcons?.data?.find(
                (i) => i.name === item.name
              );
              return (
                <Link
                  to={FOCUS_AREA_LINKS[item.name]}
                  className={`t-relative t-flex-none t-w-[200px] t-h-[200px] t-flex !i-items-start !t-py-4 t-gap-2 t-rounded-[0.2rem] t-px-2 t-ml-0 t-mr-2 `}
                  style={{
                    backgroundImage: `linear-gradient(180deg, rgba(29, 29, 31, 0.10) 0%, rgba(29, 29, 31, 0.75) 100%), url(${
                      process.env.REACT_APP_PUBLIC_URL
                    }${item?.url.replace("focusareas", "focusarea")})`,
                    backgroundSize: "cover",
                  }}
                  key={"focus-areas-home-" + index}
                >
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}${focusIcon?.url}`}
                    alt=""
                    className="t-w-5 t-h-5 t-mb-[-2px] t-mt-auto"
                  />
                  <p
                    className="t-text-white t-font-[600] t-text-[15px] t-leading-4 t-m-0 t-mt-auto"
                    style={{ fontFamily: "Roboto" }}
                  >
                    {item?.name}
                  </p>
                </Link>
              );
            })}
          </div>
        </section>

        <section className="services section-padding pb-0 t-bg-gray-100 t-h-[460px] md:t-h-[518px]">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title-wrapper">
                  <h3 className="t-text-[24px] md:t-text-[30px] t-text-center t-font-bold t-mb-8 t-text-tertiary">
                    {data[1] && (
                      <span
                        dangerouslySetInnerHTML={{ __html: data[1]?.title }}
                      />
                    )}
                  </h3>
                  <h6 className="sub-title-uppercase t-text-center">
                    {data[1] && (
                      <span
                        dangerouslySetInnerHTML={{ __html: data[1]?.contents }}
                      />
                    )}
                  </h6>
                </div>
              </div>
            </div>
            <div className="sm:t-hidden t-flex t-flex-row t-items-center t-gap-4">
              <img src={productImg} alt="" className="!t-h-[80%] !t-w-[30%]" />
              <div className="t-flex t-flex-col t-justify-between t-gap-8 t-mt-4 ">
                {data[12] && (
                  <h5
                    className="t-text-[18px] t-text-tertiary t-font-bold"
                    dangerouslySetInnerHTML={{ __html: data[12]?.title }}
                  />
                )}
                {data[13] && (
                  <h5
                    className="t-text-[18px] t-text-tertiary t-font-bold"
                    dangerouslySetInnerHTML={{ __html: data[13]?.title }}
                  />
                )}
                {data[14] && (
                  <h5
                    className="t-text-[18px] t-text-tertiary t-font-bold"
                    dangerouslySetInnerHTML={{ __html: data[14]?.title }}
                  />
                )}
              </div>
            </div>
            <div className="!t-hidden sm:!t-flex row gy-4 t-items-start t-justify-center">
              <div className="col-xl-3 col-md-6">
                <div className="services-item">
                  <img
                    src={picture13}
                    alt=""
                    className="img-fluid d-none d-sm-block"
                  />
                  <div className="services-meta">
                    {data[12] && (
                      <h4
                        className="col-xl-8 px-0"
                        dangerouslySetInnerHTML={{ __html: data[12]?.title }}
                      />
                    )}
                    {data[12] && (
                      <p
                        dangerouslySetInnerHTML={{ __html: data[12]?.contents }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="services-item">
                  <img
                    src={picture14}
                    alt=""
                    className="img-fluid d-none d-sm-block"
                  />
                  <div className="services-meta">
                    {data[13] && (
                      <h4
                        className="col-xl-8 px-0"
                        dangerouslySetInnerHTML={{ __html: data[13]?.title }}
                      />
                    )}
                    {data[13] && (
                      <p
                        dangerouslySetInnerHTML={{ __html: data[13]?.contents }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="services-item">
                  <img
                    src={picture15}
                    alt=""
                    className="img-fluid d-none d-sm-block"
                  />
                  <div className="services-meta">
                    {data[14] && (
                      <h4
                        className="col-xl-8 px-0"
                        dangerouslySetInnerHTML={{ __html: data[14]?.title }}
                      />
                    )}
                    {data[14] && (
                      <p
                        dangerouslySetInnerHTML={{ __html: data[14]?.contents }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="experts section-padding-exports !t-bg-white !t-pb-4 !t-pt-10 t-h-[420px]  md:t-h-[555px]">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title-wrapper mb-4">
                  <h3 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-tertiary">
                    {data[5] && (
                      <span
                        dangerouslySetInnerHTML={{ __html: data[5]?.title }}
                      />
                    )}
                  </h3>
                </div>
              </div>
            </div>

            <div className="experts-wrapper t-flex t-items-start t-gap-4">
              <div className="col-6">
                <div className=" img-style !t-flex !t-flex-col !t-items-center">
                  <img
                    src={picture7}
                    alt=""
                    className="!t-h-auto !t-w-[80%] md:!t-w-[40%]"
                  />
                  <h3>{expertsData[0] && expertsData[0]?.data?.title}</h3>
                  {expertsData[0] && (
                    <p
                      className="t-leading-[100%]"
                      dangerouslySetInnerHTML={{
                        __html: expertsData[0]?.data?.contents,
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="img-style !t-flex !t-flex-col !t-items-center">
                  <img
                    src={picture8}
                    alt=""
                    className="!t-h-auto !t-w-[80%] md:!t-w-[40%]"
                  />
                  <h3>{expertsData[1] && expertsData[1]?.data?.title}</h3>
                  {expertsData[1] && (
                    <p
                      className="t-leading-[100%]"
                      dangerouslySetInnerHTML={{
                        __html: expertsData[1]?.data?.contents,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="steps section-padding video-bg d-flex align-items-center justify-content-center t-h-[600px]">
          <div
            dangerouslySetInnerHTML={{
              __html: `<video className="sm:!t-hidden" autoplay loop muted playsinline>
                      <source
                          src=${NoMorePillsVideo}
                          data-src="assets/riise-people-sport-energy-granules.mp4"
                          type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>`,
            }}
          />
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-12 text-center !t-max-w-[500px] t-mx-auto">
                <h3 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-white">
                  {data[3] && (
                    <span
                      dangerouslySetInnerHTML={{ __html: data[3]?.title }}
                    />
                  )}
                </h3>
                <h6>
                  {data[3] && (
                    <span
                      dangerouslySetInnerHTML={{ __html: data[3]?.contents }}
                    />
                  )}
                </h6>
                <div className="text-center mt-5">
                  <div className="col-auto">
                    <Link to={slug}>
                      <span className="btn btn-custom-tertiary !t-rounded-lg">
                        {takeTheTestTitle}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faq">
          <div className="container-fluid text-center px-0 ">
            <div className="row gx-0 !t-max-w-[1330px] !t-mx-auto">
              <div className="col-lg-12 align-items-center d-flex faq-home">
                <EffectAccordion data={data} />
              </div>
            </div>
          </div>
        </section>

        <section
          ref={sectionRef}
          className="steps section-padding !t-pb-0 !t-bg-gray-100 t-h-[501px]  md:t-h-[480px]"
        >
          <div className="container">
            <h3 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-tertiary">
              {t(CustomerReviews)}
            </h3>
            <Reviews />
          </div>
        </section>

        <section className="steps section-padding !t-pb-12 !t-bg-white t-h-[856px] md:t-h-[410px]">
          <div className="container">
            <div className="row gy-4 align-items-center">
              <div className="col-xxl-8 col-lg-9 me-auto">
                <h3 className="t-text-[24px] md:t-text-[30px] t-font-bold t-text-tertiary">
                  {data[8] && (
                    <span
                      dangerouslySetInnerHTML={{ __html: data[8]?.title }}
                    />
                  )}
                </h3>
                <h6 className="">
                  {data[8] && (
                    <span
                      dangerouslySetInnerHTML={{ __html: data[8]?.contents }}
                    />
                  )}
                </h6>
                <div className="!t-mt-8">
                  <div className="col-auto">
                    <Link to={slug}>
                      <span className="btn btn-custom-tertiary !t-rounded-lg">
                        {takeTheTestTitle}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <img src={picture9} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
        <section className="steps section-padding !t-pb-8 t-h-[600px]  md:t-h-[605px]">
          <div className="t-mb-8">
            <h3 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-tertiary">
              {t(FormulaHeading)}
            </h3>
          </div>

          <PersonalFormulasSection />
          <div className="!t-mt-8 t-flex t-items-center t-justify-center">
            <div className="col-auto !t-mx-auto">
              <Link to={slug}>
                <span className="btn btn-custom-secondary !t-rounded-lg">
                  {t(PersonaliseMine)}
                </span>
              </Link>
            </div>
          </div>
        </section>

        <section className="advantages section-padding !t-pb-8 !t-bg-secondary t-h-[770px]  md:t-h-[376px]">
          <div className="container">
            <h3 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-white t-mb-8">
              {t(ItMattersToUs)}
            </h3>
            <div className="row gy-2 gx-sm-5">
              <div className="col-lg-4 mb-lg-0">
                <div className="advantages-box">
                  <p className=" !t-text-left !t-text-white !t-m-0">
                    {data[9] && (
                      <span
                        className="!t-text-[18px]"
                        dangerouslySetInnerHTML={{ __html: data[9].title }}
                      />
                    )}
                  </p>
                  {data[9] && (
                    <div
                      className="!t-mt-[-12px]"
                      dangerouslySetInnerHTML={{ __html: data[9].contents }}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-lg-0">
                <div className="advantages-box">
                  <p className="!t-text-left !t-text-white !t-m-0">
                    {data[10] && (
                      <span
                        className="!t-text-[18px]"
                        dangerouslySetInnerHTML={{ __html: data[10].title }}
                      />
                    )}
                  </p>
                  {data[10] && (
                    <div
                      dangerouslySetInnerHTML={{ __html: data[10].contents }}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-4 mb-lg-0">
                <div className="advantages-box">
                  <p className="!t-text-left !t-text-white !t-m-0">
                    {data[11] && (
                      <span
                        className="!t-text-[18px]"
                        dangerouslySetInnerHTML={{ __html: data[11].title }}
                      />
                    )}
                  </p>
                  {data[11] && (
                    <div
                      className="!t-text-[15px]"
                      dangerouslySetInnerHTML={{ __html: data[11].contents }}
                    />
                  )}
                </div>
              </div>
            </div>
            {/* {quiz_id && !token && (
              <div className="sm:t-hidden t-w-14 t-h-14 t-fixed t-bottom-5 t-right-5 t-z-50 t-bg-secondary t-rounded-full t-p-2 t-flex t-items-center t-justify-center">
                <div className="t-relative">
                  <Link className="cart" to="/product-suggestion">
                    <img
                      src={cart}
                      alt=""
                      className="img-fluid -t-ml-1"
                      width={35}
                    />
                  </Link>
                  <div className="!t-h-3 !t-w-3 !t-rounded-full t-bg-red-500 t-absolute -t-right-1 t-top-0" />
                </div>
              </div>
            )} */}
          </div>
        </section>
        <section className="steps section-padding !t-pb-8 !t-bg-white t-h-[300px] md:t-h-[312px]">
          <div className="t-mb-8">
            <h3 className="t-text-[24px] md:t-text-[30px] !t-text-center t-font-bold t-text-tertiary">
              {t(HereMainHeading)}
            </h3>
          </div>

          <HereForYou />
        </section>
      </main>
      <a
        href="https://wa.me/33671498040"
        target="_blank"
        rel="noreferrer"
        className="t-fixed t-bottom-24 sm:t-bottom-5 t-right-7 t-z-50"
      >
        <img
          src={Whatsapp}
          alt=""
          className="t-h-10 t-w-10 md:t-h-15 md:t-w-15"
        />
      </a>
    </>
  );
};

export default Home;
